<template>
    <div class="app-body">
		<bread-crumb></bread-crumb>
        <el-card class=" main orderdetail">
            <div class="orderdetail-top a-flex-rfsc">
                <span class="a-fs-16 a-fw-700">订单：{{ detail.orderSn }}</span>
                <el-tag effect="plain" type="info" class="a-ml-16"> <span class="roundspan"
                        :class="{ 'grey': detail.orderStatus == 3 || detail.orderStatus == 5, 'red': detail.orderStatus == 4 }"></span>{{ detail.orderStatus | initDic(orderStatusDic) }}
                </el-tag>
                <div class="a-flex-rfsc" v-if="detail.anxinService ">
                    <el-image
                        style="width: 21px;height: 16px !important;margin: 0 5px;"
                        fit='contain'
                        src="https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/wxapp/rest-charge-icon.png"></el-image>
                    <span style="color: #FE7B11;" class="a-fs-14 a-fw-500">安心充服务保障中</span>
                </div>
            </div>
            <div :class="(detail.orderStatus == 3 || detail.orderStatus == 4 || detail.status == 5) ? 'orderdetail-step-cancle' : 'orderdetail-step'">
                <el-steps :active="active" :finish-status="(detail.status == 3 || detail.status == 4 || detail.status == 5) ? 'error' : 'success'" align-center>
                    <el-step title="订单创建" :description="detail.createTime" icon="el-icon-my-yk_yuanquan_fill"></el-step>
                    <el-step title="开始充电" :description="detail.chargingStartTime" icon="el-icon-my-yk_yuanquan_fill"></el-step>
                    <el-step title="充电结束" :description="detail.chargingEndTime" icon="el-icon-my-yk_yuanquan_fill"></el-step>
                    <el-step title="订单结束" :description="detail.orderEndTime" icon="el-icon-my-yk_yuanquan_fill"></el-step>
                    <el-step title="已支付" :description="detail.payTime" icon="el-icon-my-yk_yuanquan_fill"></el-step>
                </el-steps>
            </div>
            <div class="orderdetail-box">
                <div class="a-fs-16 a-fw-700">订单信息</div>
                <div class="orderdetail-box-orderinfo">
                    <div class="a-flex-rfsc title">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">用户信息</span>
                    </div>
                    <div class="content a-flex-rfsc a-flex-wrap a-w-100">
                        <div>
                            <span>用户名</span>
                            <le-jumpto-detail 
                                url="/userMGT/userMGT-detail" 
                                :dQuery="{ 'id': detail.userId }">
                                <span>{{ detail.nickName }}</span>
                            </le-jumpto-detail>
                        </div>
                        <div>
                            <span>手机号</span>
                            <span>{{ userInfo.dataPermission == 77 ? util.hidePhoneNumber(detail.phone) : detail.phone }}</span>
                        </div>
                    </div>
                    <div class="a-flex-rfsc title">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">设备信息</span>
                    </div>
                    <div class="content a-flex-rsbc a-flex-wrap a-w-100">
                        <div>
                            <span>设备编号</span>
                            <le-jumpto-detail url="/device/device-info" :dQuery="{ 'deviceCode': detail.deviceCode }">
                                <span>{{ detail.deviceCode }}</span>
                            </le-jumpto-detail>
                        </div>
                        <div>
                            <span>充电仓口</span>
                            <span>{{detail.boxId+1 + '号仓口'}}</span>
                        </div>
                        <div>
                            <span>设备地址</span>
                            <le-jumpto-detail url="/station/station-detail" :dQuery="{ 'stationId': detail.stationId }">
                                <span>{{ detail.stationAddress }}</span>
                            </le-jumpto-detail>

                        </div>
                    </div>
                    <div class="a-flex-rfsc title">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">充电信息</span>
                    </div>
                    <div class="content a-flex-rsbc a-flex-wrap a-w-100">
                        <div>
                            <span>下单时间</span>
                            <span>{{ detail.placeTime || '-' }}</span>
                        </div>
                        <div>
                            <span>开柜时间</span>
                            <span>{{ detail.orderStartTime || '-' }}</span>
                        </div>
                        <div>
                            <span>取电时间（结束时间）</span>
                            <span>{{ detail.orderEndTime || '-' }}</span>
                        </div>
                        <div>
                            <span>充电开始时间</span>
                            <span>{{ detail.chargingStartTime || '-' }}</span>
                        </div>
                        <div>
                            <span>充电结束时间</span>
                            <span>{{ detail.chargingEndTime || '-' }}</span>
                        </div>
                        <div>
                            <span>服务费收取开始时间</span>
                            <span>{{ detail.orderStartTime || '-' }}</span>
                        </div>
                        <div>
                            <span>服务费收取结束时间</span>
                            <span>{{ detail.orderEndTime || '-' }}</span>
                        </div>
                        <div>
                            <span>电量</span>
                            <span>{{ detail.chargingEnergy || '-' }}度</span>
                        </div>
                        <div>
                            <span>电费</span>
                            <span>￥{{ util.numFormat(detail.totalChargingFee) }}</span>
                        </div>
                        <div>
                            <span>占位服务费</span>
                            <span>￥{{ util.numFormat(detail.totalServiceFee) }}</span>
                        </div>
                        <div>
                            <span>充电服务费</span>
                            <span>￥{{ util.numFormat(detail.chargeServiceFee) }}</span>
                        </div>
                        <div>
                            <span>管理费</span>
                            <span>￥{{ util.numFormat(detail.managermentFee) }}</span>
                        </div>
                        <div>
                            <span>订单状态</span>
                            <div>
                                <span class="roundspan" :class="{ 'grey': detail.status == 3, 'red': detail.status == 4 }"></span>
                                <span>{{ detail.orderStatus | initDic(orderStatusDic) }}</span>
                            </div>
                        </div>
                        <div>
                            <span>充电结束原因</span>
                            <span>{{ detail.chargingEndReason || '-' }}</span>
                        </div>
                        <div>
                            <span>订单结束原因</span>
                            <span>{{ detail.endReason || '-' }}</span>
                        </div>
                        <div v-if="chargePlan.type == 6 || chargePlan.type == 8">
                            <span>购买金额</span>
                            <span>￥{{ util.numFormat(detail.planAmount) }}</span>
                        </div>
                        <div v-else>
                            <span>购买时长</span>
                            <span>{{ detail.chargingMinutes }} 分钟</span>
                        </div>
                        <div>
                            <span>充电时长</span>
                            <span>{{ detail.actualMinutes || '0' }}分钟</span>
                        </div>
                        <div>
                            <span>计费标准</span>
                            <span>{{ detail.payRule || '-' }}</span>
                        </div>
                    </div>
                    <div class="a-flex-rfsc title">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">服务费明细</span>
                    </div>
                    <div class="content1 a-flex-rfsfs a-flex-wrap a-w-100">
                        <div>
                            <span>服务费明细</span>
                            <div class="a-flex-cfsfs">
                                <span style="line-height: 30px;" v-for="(item,index) in serviceTimeDetails" :key="index">
                                    {{ item.startDateTimeText }} 至 {{ item.endDateTimeText }}，服务费
                                    ￥{{ util.numFormat(item.price) }}元，
                                    {{ feeRuleTypeDic[item.ruleFeeType] || '' }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="a-flex-rfsc title">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">支付信息</span>
                    </div>
                    <div class="content a-flex-rfsfs a-flex-wrap a-w-100">
                        <div>
                            <span>支付类型</span>
                            <span>{{ detail.payChannel | initDic(payChannelDic) }}</span>
                        </div>
                        <div>
                            <span>支付来源</span>
                            <span>{{ orderSourceDic[detail.orderSource] || detail.orderSource }}</span>
                        </div>
                        <div>
                            <span>产生费用</span>
                            <span>&yen; {{ util.numFormat(detail.orderPrice) }}</span>
                        </div>
                        <div>
                            <span>支付金额</span>
                            <span>&yen; {{ util.numFormat(detail.payAmount) }}</span>
                        </div>
                        <div>
                            <span>实际收益</span>
                            <span>&yen; {{ util.numFormat(realAmount) }}</span>
                        </div>
                    </div>
                    <div class="a-flex-rfsc title">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">支付信息明细</span>
                    </div>
                    <el-table :border='true' :data="payInfo" style="width: 800px;margin-left:14px">
                        <el-table-column label=""  prop='desc'></el-table-column>
                        <el-table-column label="支付金额" >
                            <template slot-scope="{ row }">
                                <span v-if="row.discountType == 4">通用：￥{{ util.numFormat(row.details.payGlobalPoints || 0) }}，站点：￥{{ util.numFormat(row.details.paySatationPoints || 0) }}</span>
                                <span v-else>￥{{ util.numFormat(row.amount) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="退款金额">
                            <template slot-scope="{ row }">
                                <span v-if="row.discountType == 4">通用：￥{{ util.numFormat(row.details.refundGlobalPoints || 0) }}，站点：￥{{ util.numFormat(row.details.refundSatationPoints || 0) }}</span>
                                <span v-else>￥{{ util.numFormat(row.refoundAmount) }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="a-flex-rfsc title" v-if="restCharge">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">安心充支付信息</span>
                    </div>
                    <div class="content a-flex-rfsfs a-flex-wrap a-w-100" v-if="restCharge">
                        <div>
                            <span>支付时间</span>
                            <span>{{ detail.placeTime }}</span>
                        </div>
                        <div>
                            <span>支付方式</span>
                            <span>{{ detail.payChannel | initDic(payChannelDic) }}</span>
                        </div>
                        <div>
                            <span>支付类型</span>
                            <span>{{ detail.payWay == 1 ? '现付' : '后付' }}</span>
                        </div>
                        <div>
                            <span>支付状态</span>
                            <span>{{ restCharge.payStatus | initDic(packagePayStatusDic) }}</span>
                        </div>
                        <div>
                            <span>订单状态</span>
                            <div>
                                <span class="roundspan"
                                    :class="{ 'grey': restCharge.orderStatus == 3, 'red': restCharge.orderStatus == 4 }"></span>
                                <span>{{ restCharge.orderStatus | initDic(packageOrderStatusDic) }}</span>
                            </div>
                        </div>
                        <div>
                            <span>订单来源</span>
                            <span>{{ orderSourceDic[detail.orderSource] || detail.orderSource }}</span>
                        </div>
                        <div>
                            <span>支付金额</span>
                            <span>&yen; {{ util.numFormat(restCharge.payAmount) }}</span>
                        </div>
                        <div>
                            <span>退款金额</span>
                            <span>&yen; {{ util.numFormat(restCharge.refundAmount) }}</span>
                        </div>
                        <div>
                            <span>实际费用</span>
                            <span>&yen; {{ util.numFormat(restCharge.payAmount - restCharge.refundAmount) }}</span>
                        </div>
                    </div>
                    <div class="a-flex-rfsc title" v-if="restCharge">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">安心充支付信息明细</span>
                    </div>
                    <el-table :border='true' :data="restChargePayInfo" style="width: 800px;margin-left:14px;margin-top: 14px" v-if="restCharge">
                        <el-table-column label=""  prop='desc'></el-table-column>
                        <el-table-column label="支付金额" >
                            <template slot-scope="{ row }">
                                <span v-if="row.discountType == 4">通用：￥{{ util.numFormat(row.details.payGlobalPoints || 0) }}，站点：￥{{ util.numFormat(row.details.paySatationPoints || 0) }}</span>
                                <span v-else>￥{{ util.numFormat(row.amount) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="退款金额">
                            <template slot-scope="{ row }">
                                <span v-if="row.discountType == 4">通用：￥{{ util.numFormat(row.details.refundGlobalPoints || 0) }}，站点：￥{{ util.numFormat(row.details.refundSatationPoints || 0) }}</span>
                                <span v-else>￥{{ util.numFormat(row.refoundAmount) }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="a-flex-rfsc title" v-if="couponOrder">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">优惠券包订单信息</span>
                    </div>
                    <div class="content a-flex-rfsfs a-flex-wrap a-w-100" v-if="couponOrder_goodsInfo">
                        <div>
                            <span>订单编号</span>
                            <span class="a-c-blue font-point" style="color: #007aff" @click="handleToCouponOrder">{{ couponOrder.orderSn || '-' }}</span>
                        </div>
                        <div>
                            <span>关联活动</span>
                            <span>{{ couponOrder_goodsInfo.activity.activityTitle }}</span>
                        </div>
                        <div>
                            <span>关联优惠券</span>
                            <span>{{ couponOrder_goodsInfo.goodsDetails.name }}</span>
                        </div>
                        <div>
                            <span>关联优惠券ID</span>
                            <span>{{ couponOrder_goodsInfo.goodsDetails.id }}</span>
                        </div>
                        <div>
                            <span>订单金额</span>
                            <span>￥{{ util.numFormat(couponOrder.orderPrice) }}</span>
                        </div>
                        <div>
                            <span>实际支付</span>
                            <span>￥{{ util.numFormat(couponOrder.payAmount - couponOrder.refundAmount) }}</span>
                        </div>
                        <div>
                            <span>支付状态</span>
                            <span>{{ couponOrder.payStatus | initDic(packagePayStatusDic) }}</span>
                        </div>
                    </div>
                    <div class="a-flex-rfsc title" v-if="couponOrder">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">优惠券包支付信息</span>
                    </div>
                    <el-table :border='true' :data="couponPayInfo" style="width: 800px;margin-left:14px;margin-top: 14px" v-if="couponOrder">
                        <el-table-column label=""  prop='desc'></el-table-column>
                        <el-table-column label="支付金额" >
                            <template slot-scope="{ row }">
                                <span v-if="row.discountType == 4">通用：￥{{ util.numFormat(row.details.payGlobalPoints || 0) }}，站点：￥{{ util.numFormat(row.details.paySatationPoints || 0) }}</span>
                                <span v-else>￥{{ util.numFormat(row.amount) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="退款金额">
                            <template slot-scope="{ row }">
                                <span v-if="row.discountType == 4">通用：￥{{ util.numFormat(row.details.refundGlobalPoints || 0) }}，站点：￥{{ util.numFormat(row.details.refundSatationPoints || 0) }}</span>
                                <span v-else>￥{{ util.numFormat(row.refoundAmount) }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <charge-plan :chargePlan='chargePlan'></charge-plan>
                </div>
            </div>
            <div class="orderdetail-box">
                <div class="a-fs-16 a-fw-700">电量记录</div>
                <div v-show="energyList && energyList.length">
                    <le-echarts-line :echartsObj="energyCharts" ></le-echarts-line>
                </div>
                <div class="table">
                    <div class="table-header a-flex-rfsfs">
                        <span>时间</span>
                        <!-- onceEnergy -->
                        <span>单次电量</span>
                        <!-- totalEnergy -->
                        <span>累计电量</span>
                        <!-- apprentOnceEnergy -->
                        <span>视在单次电量</span>
                        <!-- apprentTotalEnergy -->
                        <span>视在累计电量</span>
                        <!-- power -->
                        <span>有功功率</span> 
                        <!-- apperentPower -->
                        <span>视在功率</span>
                        <!-- rmsCurrent -->
                        <span>电流</span>
                    </div>
                    <div class="a-flex-rfsfs" v-for="(item, index) in energyList" :key="index">
                        <span>{{ item.createTime }}</span>
                        <span>{{ item.onceEnergy || 0 }}kwh</span>
                        <span>{{ item.totalEnergy || 0 }}kwh</span>
                        <span>{{ item.apprentOnceEnergy|| 0 }}kwh</span>
                        <span>{{ item.apprentTotalEnergy || 0 }}kwh</span>
                        <span>{{ item.power || 0 }}W</span>
                        <span>{{ item.apperentPower || 0 }}W</span>
                        <span>{{ item.rmsCurrent || 0 }}A</span>
                    </div>
                </div>
            </div>
            <!-- <div class="a-line-t-e0 footerBox" v-if="(detail.status == 2 || detail.status == 3) && detail.amount > 0">
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" type="danger" @click="refund" plain>
                    &nbsp;&nbsp;&nbsp;退款&nbsp;&nbsp;&nbsp;
                </el-button>
            </div> -->
            <le-station-list :tabledata='[]'></le-station-list>
        </el-card>
    </div>
</template>
<script>
import {
    mapState,
    mapActions
} from 'vuex';
import util from '../../utils/util'
import chargePlan from '@/views/order/child/chargePlan'
export default {
    components: {
        chargePlan
    },
    data() {
        return {
            util: util,
            active: 0,
            detail: {},
            serviceTimeDetails: [],
            orderStatusDic: [],
            payChannelDic: [],
            packagePayStatusDic: [],//套餐支付状态字典
            packageOrderStatusDic: [],
            payInfo: [],
            restCharge: '',//安心充订单信息
            restChargePayInfo: [],//安心充支付信息明细
            couponOrder: '',//优惠券订单信息
            couponOrder_goodsInfo: '',//优惠券订单商品信息
            couponPayInfo: [],//优惠券支付信息
            orderSourceDic:{
                'hichar.user.wxapp': '毛豆充小程序',
                'hichar.company.web.jd': '毛豆充web管理系统',
                'hichar.company.wxapp.jd': '毛豆充商户助手'
            },
            feeRuleTypeDic: {
                0: '基础收费',
                1: '充电器充电时间内，',
                2: '充电器不充电时间内，',
                3: '',
                4: '充电器充电时间内功率服务费，'
            },
            feeRuleBackTypeDic: {  // 生效条件反向字典
                0: '基础收费',
                1: '充电器不充电时间内，',
                2: '充电器充电时间内，',
                3: '',
                4: '充电器充电时间内功率服务费，'
            },
            chargePlan: '',
            energyList: null,
            energyCharts:{
                id: 'energyCharts',
                xAxisData: [],
                seriesData0: [],
                seriesData1: [],
                seriesData2: [],
                seriesData3: [],
                seriesData4: [],
                seriesName0: '累计电量',
                seriesName1: '单次电量',
                seriesName2: '电流',
                seriesName3: '有功功率',
                seriesName4: '视在功率',
                seriesUnit0: 'kwh',
                seriesUnit1: 'kwh',
                seriesUnit2: 'A',
                seriesUnit3: 'W',
                seriesUnit4: 'W',
                yAxisIndex2: 1,
                yAxisIndex3: 2,
                yAxisIndex4: 2,
            }
        }
    },
    mounted() {
        this.$getDic('payChannel').then(res=>{ this.payChannelDic = res; })
        this.$getDic('boxOrderStatus').then(res=>{ this.orderStatusDic = res; })
        this.$getDic('payStatus').then(res=>{ this.packagePayStatusDic = res; })
        this.$getDic('orderStatus').then(res=>{ this.packageOrderStatusDic = res; })
        this.getOrderInfoById();
        
        
    },
    filters:{
        formatMinute (val) {
            if(val % 60 == 0){
                return (val / 60).toFixed(0) + '小时'
            }else{
                return val + '分钟'
            }
        }
    },
    computed:{
        serviceDuration () {
            // 特殊时间段总服务时长
            let duration = 0
            if(this.chargePlan.ruleList && this.chargePlan.ruleList.length){
                this.chargePlan.ruleList
                for (var i = 0; i < this.chargePlan.ruleList.length; i++) {
                    let item = this.chargePlan.ruleList[i]
                    if(item.startTime == item.endTime){ //开始时间 == 结束时间，则认为是全天时间段
                        return 24
                    }else{
                        let h = this.$getDay.getTimeDiff(item.startTime,item.endTime)
                        duration += h
                    }
                }
                return duration
            }else{
                return 0
            }
        },
        realAmount () {
            // let refoundAmountAll = 0 // 所有优惠的退款
            // let discountPayAmountAll = 0
            // this.detail.orderDiscountInfoList.map(item=>{
            //     refoundAmountAll += item.refundPayAmount
            //     discountPayAmountAll += item.discountPayAmount
            // })
            // return this.detail.payAmount + discountPayAmountAll - this.detail.refundAmount - refoundAmountAll
            return this.detail.payAmount - this.detail.refundAmount
        },
        ...mapState({
            userInfo: state => state.user.user,
        })
    },
    methods: {
        //获取订单详情
        getOrderInfoById() {
            this.$Axios._get({
                url: this.$Config.apiUrl.cabinetOrderInfo,
                params: {
                    boxOrderId: this.$route.query.orderId
                },
            }).then((res) => {
                if (res.result.code == 0) {
                    this.getOrderPower(res.result.data.id);
                    this.detail = res.result.data
                    try{
                        this.serviceTimeDetails = res.result.data.serviceTimeDetails?JSON.parse(res.result.data.serviceTimeDetails):[]
                        this.serviceTimeDetails = this.serviceTimeDetails.sort(function (a, b) {
							return a.startDateTime - b.startDateTime;
						})
                        this.serviceTimeDetails = this.serviceTimeDetails.map(res=>{
                            return {
                                ...res,
                                startDateTimeText: this.$getDay.getTimeStampToStr(res.startDateTime),
                                endDateTimeText: this.$getDay.getTimeStampToStr(res.endDateTime),
                            }
                        });
                    }catch(err){
                        console.log(err);
                    }
                    if (this.detail.createTime) {
                        this.active = 0
                    }
                    if (this.detail.chargingStartTime) {
                        this.active = 1
                    }
                    if (this.detail.chargingEndTime) {
                        this.active = 2
                    }
                    if (this.detail.orderEndTime) {
                        this.active = 3
                    }
                    if (this.detail.payTime) {
                        this.active = 4
                    }
                    // 优惠信息
                    let refoundAmountAll = 0
                    let discountArr = this.detail.orderDiscountInfoList.map(item=>{
                        refoundAmountAll += item.refundPayAmount
                        return {
                            ...item,
                            amount: item.discountPayAmount,
                            refoundAmount: item.refundPayAmount,
                            desc: this.$Config.discountTypeDic[item.discountType],
                            details: item.details ? JSON.parse(item.details) : ''
                        }
                    })
                    refoundAmountAll += this.detail.refundAmount
                    // this.payInfo[0] = {
                    //     amount: this.detail.planAmount,
                    //     refoundAmount: refoundAmountAll,
                    //     desc: '订单总金额'
                    // }
                    this.payInfo[1] = {
                        amount: this.detail.payAmount,
                        refoundAmount: this.detail.refundAmount,
                        desc: '现金'
                    }
                    this.payInfo = this.payInfo.concat(discountArr)
                    if(this.detail.chargingPlanDetails){
                        try {
                            this.chargePlan = JSON.parse(this.detail.chargingPlanDetails)
                        } catch (error) {

                        }
                    }

                    // 安心充订单信息
                    this.restCharge = res.data.superOrderItem.find(re=>{
                        return re.goodsType == 2
                    })
                    if(this.restCharge){
                        this.restChargePayInfo = this.restCharge.orderDiscountInfoList ? this.restCharge.orderDiscountInfoList : []
                        this.restChargePayInfo = this.restChargePayInfo.map(item=>{
                            return {
                                amount: item.discountPayAmount,
                                refoundAmount: item.refundPayAmount,
                                desc: this.$Config.discountTypeDic[item.discountType]
                            }
                        })
                        this.restChargePayInfo.unshift({
                            amount: this.restCharge.payAmount,
                            refoundAmount: this.restCharge.refundAmount,
                            desc: '现金'
                        })
                    }

                    // 优惠券订单信息
                    this.couponOrder = res.data.superOrderItem.find(re=>{
                        return re.goodsType == 4
                    })
                    if(this.couponOrder) {
                        this.couponPayInfo = this.couponOrder.orderDiscountInfoList ? this.couponOrder.orderDiscountInfoList : []
                        this.couponPayInfo = this.couponPayInfo.map(item=>{
                            return {
                                amount: item.discountPayAmount,
                                refoundAmount: item.refundPayAmount,
                                desc: this.$Config.discountTypeDic[item.discountType]
                            }
                        })
                        this.couponPayInfo.unshift({
                            amount: this.couponOrder.payAmount,
                            refoundAmount: this.couponOrder.refundAmount,
                            desc: '现金'
                        })

                        try {
                            this.couponOrder_goodsInfo = this.couponOrder.goodsInfo ? JSON.parse(this.couponOrder.goodsInfo) : ''
                            this.couponOrder_goodsInfo = {
                                ...this.couponOrder_goodsInfo,
                                goodsDetails: this.couponOrder_goodsInfo.goodsDetails?JSON.parse(this.couponOrder_goodsInfo.goodsDetails):""
                            }
                        } catch (error) {
                            
                        }
                    }
                }
            }).catch(err=>{
            })
        },
        handleToCouponOrder () {
            this.$router.push({
                path:'/order/coupon-order-info',
                query: {
                    orderId: this.couponOrder.id,
                    orderSn: this.couponOrder.orderSn
                }
            })
        },
        //获取电量记录
        getOrderPower(id) {
            this.$Axios._get({
                url: this.$Config.apiUrl.cabinetEnergyList,
                params: {
                    boxOrderId: id
                },
            }).then((res) => {
                if (res.result.code == 0) {
                    this.energyList = res.result.data.map(item=>{
                        return {
                            ...item
                        }
                    })
                    this.energyCharts.xAxisData = []
                    this.energyCharts.seriesData0 = []
                    this.energyCharts.seriesData1 = []
                    this.energyCharts.seriesData2 = []
                    this.energyCharts.seriesData3 = []
                    this.energyCharts.seriesData4 = []

                    this.energyList.map((item,index)=>{
                        this.energyCharts.xAxisData.push(item.createTime)
                        this.energyCharts.seriesData0.push(item.totalEnergy)
                        this.energyCharts.seriesData1.push(item.onceEnergy)
                        this.energyCharts.seriesData2.push(item.rmsCurrent)
                        this.energyCharts.seriesData3.push(item.power)
                        this.energyCharts.seriesData4.push(item.apperentPower)
                    })
                }
            });
        },
        //退款
        refund() {
            this.$confirm('确定进行订单退款?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                this.$Axios._get({
                    url: this.$Config.apiUrl.refundOrder,
                    params: {
                        orderId: parseInt(this.$route.query.orderId)
                    },
                }).then((res) => {
                    if (res.result.code == 0) {
                        this.$message({
                            type: 'success',
                            message: '订单开始退款!'
                        })
                    } else {
                        this.$message({
                            type: 'error',
                            message: '退款失败：' + res.result.msg
                        })
                    }
                }).catch((res) => {
                    this.$message({
                        type: 'error',
                        message: '退款失败：' + res.result.msg
                    })
                })




            }).catch(() => {

            });
        }
    }
}
</script>
<style lang="scss" scoped>
.main {
    height: 100%;
    overflow: auto;
    
}

.roundspan {
    height: 8px;
    width: 8px;
    background: #007AFF;
    border-radius: 100%;
    display: inline-block;
    margin-right: 6px;

    &.grey {
        background: #C0C4CC;
    }

    &.red {
        background: #FF3B30;
    }
}

.orderdetail {

    padding-bottom: 100px;

    &-top {
        border-bottom: 1px solid #EBF0F5;
        padding: 24px 24px 16px;
    }

    &-step {
        padding: 35px 0 0;
    }

    &-box {
        font-size: 14px;
        line-height: 22px;
        padding: 56px 24px 0;

        &-orderinfo {
            .title {
                padding-top: 16px;

                span {
                    font-weight: bold;

                    &.dot {
                        width: 6px;
                        height: 6px;
                        background: #797979;

                    }
                }
            }

            .content {
                padding-left: 14px;
                padding-bottom: 8px;

                >div {
                    width: 33.3%;
                    padding-top: 16px;
                    display: flex;
                    flex-flow: row;
                    justify-content: flex-start;
                    align-content: flex-start;



                    >span {
                        &:nth-of-type(1) {
                            color: #666666;
                            margin-right: 16px;
                            // font-family: PingFangSC-Regular, PingFang SC;
                        }

                        &:nth-of-type(2) {
                            color: #333;

                            font-family: PingFangSC-Regular, PingFang SC;

                        }
                    }
                }
            }

            .content1 {
                padding-left: 14px;
                padding-bottom: 8px;
                >div {
                    padding-top: 16px;
                    display: flex;
                    flex-flow: row;
                    justify-content: flex-start;
                    align-content: flex-start;
                    >span {
                        &:nth-of-type(1) {
                            color: #666666;
                            margin-right: 16px;
                        }
                        &:nth-of-type(2) {
                            color: #333;
                            font-family: PingFangSC-Regular, PingFang SC;
                        }
                    }
                }
            }

        }

        .table {
            padding-top: 16px;

            >div {
                font-size: 14px;
                line-height: 22px;
                padding: 14px 0;
                padding-left: 8px;

                >span {
                    width: 25%;
                    color: #303133;
                }

                border-bottom:1px solid #EBF0F5;
            }

            &-header {
                background: #F5F7FA;

                border: none;

                >span {

                    color: #606366;
                    font-weight: bold;
                }

            }
        }
    }
}

/deep/ .el-card__body {
    padding: 0;
}

.order {
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background: #007AFF;
    display: inline-block;
}

.el-icon-my-yk_yuanquan_fill {
    font-size: 20px;
}

/deep/ .is-process .el-icon-my-yk_yuanquan_fill {
    color: #007AFF;
}

/deep/ .is-success .el-step__icon-inner {
    background: #007AFF;
    color: #fff;
    padding: 2px;
    border-radius: 100%;
}

/deep/ .el-step__head.is-success {
    color: #007AFF;
    border-color: #007AFF;

    .el-step__line {
        background-color: #007AFF;
    }
}

/deep/ .el-step__head.is-process {
    color: #007AFF;
    border-color: #007AFF;
}

/deep/ .el-step__title {
    color: #303133;
    font-size: 14px;
}

/deep/ .el-step__description {

    color: #909499
}

.orderdetail-step-cancle {
    padding: 35px 0 0;

    /deep/ .is-process .el-icon-my-yk_yuanquan_fill {
        color: #C0C4CC;
    }

    /deep/ .is-success .el-step__icon-inner {
        background: #C0C4CC;
        color: #fff;
        padding: 2px;
        border-radius: 100%;
    }

    /deep/ .el-step__head.is-success {
        color: #C0C4CC;
        border-color: #C0C4CC;

        .el-step__line {
            background-color: #C0C4CC;
        }
    }

    /deep/ .is-error .el-step__icon-inner {
        background: #C0C4CC;
        color: #fff;
        padding: 2px;
        border-radius: 100%;
    }

    /deep/ .el-step__head.is-error {
        color: #C0C4CC;
        border-color: #C0C4CC;

        .el-step__line {
            background-color: #C0C4CC;
        }
    }

    /deep/ .el-step__head.is-process {
        color: #C0C4CC;
        border-color: #C0C4CC;
    }

    /deep/ .el-step__head.is-process {
        color: #C0C4CC;
        border-color: #C0C4CC;
    }
}

.footerBox {
    left: 255px;
    right: 43px;
    position: fixed;
    bottom: 0;
    background: #fff;
    padding-bottom: 24px;
}
.point{
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background: #666;
}
.serviceTable{
    flex-direction: column !important;
    margin-bottom: 12px;
}
.serviceTable-item{
    padding: 6px 10px;
    background: #F5F7FA;
    border-radius: 2px;
    color: #606366;
    font-size: 14px;
    margin: 1px
}
.serviceTable-item:first-child{
    width: 180px;
}
</style>